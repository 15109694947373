import { SvgIcon, SvgIconProps } from "@mui/material";

export default function LinkedinIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 40 40">
      <path
        d="M32.9348 3.36963C33.915 3.36963 34.855 3.75899 35.5481 4.45206C36.2411 5.14513 36.6305 6.08513 36.6305 7.06528V32.9348C36.6305 33.915 36.2411 34.855 35.5481 35.5481C34.855 36.2411 33.915 36.6305 32.9348 36.6305H7.06528C6.08513 36.6305 5.14513 36.2411 4.45206 35.5481C3.75899 34.855 3.36963 33.915 3.36963 32.9348V7.06528C3.36963 6.08513 3.75899 5.14513 4.45206 4.45206C5.14513 3.75899 6.08513 3.36963 7.06528 3.36963H32.9348ZM32.0109 32.0109V22.2175C32.0109 20.6198 31.3763 19.0876 30.2466 17.9579C29.1169 16.8282 27.5847 16.1935 25.987 16.1935C24.4164 16.1935 22.587 17.1544 21.7001 18.5957V16.5446H16.5446V32.0109H21.7001V22.9011C21.7001 21.4783 22.8457 20.3142 24.2685 20.3142C24.9546 20.3142 25.6126 20.5867 26.0978 21.0719C26.5829 21.557 26.8555 22.215 26.8555 22.9011V32.0109H32.0109ZM10.5392 13.6435C11.3625 13.6435 12.1521 13.3165 12.7343 12.7343C13.3165 12.1521 13.6435 11.3625 13.6435 10.5392C13.6435 8.82072 12.2577 7.41637 10.5392 7.41637C9.71097 7.41637 8.91667 7.74538 8.33102 8.33102C7.74538 8.91667 7.41637 9.71097 7.41637 10.5392C7.41637 12.2577 8.82072 13.6435 10.5392 13.6435ZM13.1077 32.0109V16.5446H7.98919V32.0109H13.1077Z"
        fill="white"
      />
    </SvgIcon>
  );
}
