import { lazy } from "react";
import { Grid } from "@mui/material";
import TopBar from "components/common/TopBar";
import Hero from "components/Hero";
const Footer = lazy(() => import("./common/Footer"));
const Tokenomics = lazy(() => import("./Tokenomics"));
const TradingComponent = lazy(() => import("./TradingComponent"));
const LaunchAppBanner = lazy(() => import("./LaunchAppBanner"));
const Newsletter = lazy(() => import("./Newsletter"));
const Blog = lazy(() => import("./Blog"));
const Protocols = lazy(() => import("./Protocols"));
const Partners = lazy(() => import("./Partners"));
const About = lazy(() => import("./About"));

const Home = () => {
  return (
    <Grid
      container
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ overflowX: { xs: "hidden", lg: "visible" } }}
      maxWidth={{ xs: "100%", lg: "90%", xl: "70%" }}
    >
      <Grid container item>
        <TopBar />
      </Grid>
      <Grid item>
        <Hero />
      </Grid>
      <Grid item>
        <About />
      </Grid>
      <Grid item>
        <TradingComponent />
      </Grid>
      <Grid item width="100%">
        <LaunchAppBanner />
      </Grid>
      <Grid item>
        <Partners />
      </Grid>
      <Grid item>
        <Protocols />
      </Grid>
      <Grid item>
        <Tokenomics />
      </Grid>
      <Grid container item>
        <Blog />
      </Grid>
      <Grid container item>
        <Newsletter />
      </Grid>
      <Grid container item>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default Home;
