import {
  Button,
  Grid,
  Typography,
  Box,
  IconButton,
  Slide,
} from "@mui/material";
import heroWaves from "../assets/waves.svg";
import heroWavesMobile from "../assets/wavesMobile.svg";
import { useEffect, useState, useRef } from "react";
import { heroSocial } from "components/common/constants/constants";
import { motion, useInView, useAnimate } from "framer-motion";

const Hero = () => {
  const [hoverOver, setHoverOver] = useState<string>("");
  const [transition, setTransition] = useState<boolean>(false);
  const [done, setDone] = useState<boolean>(false);
  const [scope, animate] = useAnimate();
  const motionDiv = useRef(null);
  const isInView = useInView(motionDiv);

  useEffect(() => {
    setTransition(true);
  }, []);

  useEffect(() => {
    if (isInView && transition && !done) {
      animate(
        "div",
        {
          opacity: 1,
          y: 0,
          x: 0,
          width: "100%",
        },
        { duration: 1, ease: "linear" }
      );
      setDone(true);
    } else if (!done) {
      animate(
        "div",
        {
          opacity: 0,
          y: 0,
          x: "10%",
          width: "90%",
        },
        { duration: 1, ease: "linear" }
      );
    }
  }, [isInView, animate, transition, done]);

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginTop: { xl: "-6%" },
        marginBottom: { xs: "-3rem", lg: 0 },
      }}
    >
      <Slide
        direction="down"
        in={transition}
        mountOnEnter
        unmountOnExit
        timeout={1200}
      >
        <Grid
          container
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          rowGap="1rem"
          mb={{ lg: "9%", xl: "8%" }}
          mt={{ xl: 8 }}
          sx={{ zIndex: 99 }}
        >
          <Grid item order={1} mb="0.25rem">
            <Typography
              variant="h1"
              textAlign="center"
              fontSize={{ xs: "8vw", lg: "6vw", xl: "5vw" }}
            >
              Kaskade Finance
            </Typography>
          </Grid>
          <Grid
            item
            xs={10}
            lg={12}
            order={2}
            textAlign="center"
            px={{ xs: "2%", lg: "5%" }}
          >
            <Typography variant="h3" fontSize={{ xs: "1rem", lg: "2vw" }}>
              Your control panel for liquidity
            </Typography>
          </Grid>
          <Grid item xs={10} lg={7} xl={5.5} order={3}>
            <Typography
              variant="h6"
              color="secondary.main"
              fontWeight={300}
              textAlign="center"
            >
              Kaskade has pioneered the use of volume bootstrapping to control
              and direct liquidity incentives across decentralized exchanges and
              dApps on various networks.
            </Typography>
          </Grid>
          <Grid item order={4} mt={{ xs: "1.25rem", lg: "2.5rem" }}>
            <Button
              variant="contained"
              href="https://app.kaskade.finance"
              target="_blank"
              rel="nofollow noindex"
              sx={{
                width: { lg: "12.75rem", xl: "15vw" },
                height: { lg: "3.5rem", xl: "3.5vw" },
              }}
            >
              Launch App
            </Button>
          </Grid>
          <Grid
            item
            order={{ xs: 6, lg: 5 }}
            mt="2rem"
            mb={{ xs: "2vw", sm: "10vw", lg: "-1vw", xl: 0 }}
            sx={{ zIndex: 99 }}
          >
            {heroSocial.map((item) => (
              <IconButton
                key={item.name}
                onMouseOver={() => setHoverOver(item.name)}
                onMouseOut={() => setHoverOver("")}
                href={item.href}
                target="_blank"
                rel="nofollow noindex"
                sx={{ mx: { xl: "0.15vw" } }}
              >
                {hoverOver === item.name ? item.gradientIcon : item.whiteIcon}
              </IconButton>
            ))}
          </Grid>
        </Grid>
      </Slide>
      <Grid
        order={{ xs: 6, lg: 7 }}
        item
        xs={12}
        sx={{
          marginTop: { xs: "-35vw", sm: "-45vw", lg: "-28vw" },
        }}
        ref={scope}
        display="flex"
        justifyContent="flex-end"
        width="100%"
      >
        <Box
          ref={motionDiv}
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100vw",
          }}
        >
          <motion.div id="box">
            <Box
              sx={{
                background: {
                  xs: `transparent url('${heroWavesMobile}') center/100% no-repeat`,
                  lg: `transparent url('${heroWaves}') center/100% no-repeat`,
                },
                backgroundSize: "100%",
                height: transition ? { xs: "70vw", lg: "42vw" } : 0,
                width: "100vw",
                maxWidth: "100vw",
                zIndex: -999,
                position: "relative",
              }}
            />
          </motion.div>
        </Box>
      </Grid>
    </Box>
  );
};

export default Hero;
