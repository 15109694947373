import "@fontsource/unbounded";
import "@fontsource/outfit";

import Home from "./components/Home";
import { theme } from "./utils/theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import Contact from "./components/Contact";
import { Suspense } from "react";
import LoadingSpinner from "components/common/LoadingSpinner";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Suspense fallback={<LoadingSpinner />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
