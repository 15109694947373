import {
  Box,
  Grid,
  Typography,
  OutlinedInput,
  Button,
  Checkbox,
} from "@mui/material";
import GreenTag from "./common/GreenTag";
import TopBar from "./common/TopBar";
import ContactFormBackground from "../assets/contactFormBackground.svg";
import Footer from "./common/Footer";
import { useForm } from "../hooks/useForm";
import { useState } from "react";

const Contact = () => {
  const { form, success, sendEmail } = useForm();
  const [isChecked, setIsChecked] = useState<boolean>(true);

  const successColor = () => {
    if (success === "sent_successfully") {
      return "success.light";
    } else if (success === "sent_with_error") {
      return "error.light";
    } else {
      return "none";
    }
  };

  const getMessage = () => {
    if (
      (success === "not_sent_yet" || success === "sent_with_error") &&
      !isChecked
    ) {
      return "Please check the box to continue.";
    } else if (success === "sent_successfully") {
      return "Your message was sent successfully!";
    } else if (success === "sent_with_error") {
      return "Something went wrong. Please try again.";
    }
  };

  return (
    <form ref={form} onSubmit={sendEmail}>
      <Box
        sx={{
          background: `transparent url('${ContactFormBackground}') no-repeat`,
          backgroundSize: "100%",
          zIndex: -999,
          height: "100vh",
          marginTop: { xs: "35vw", lg: "7%", xl: "5%" },
        }}
      />
      <Grid
        container
        flexDirection="column"
        justifyContent="center"
        sx={{ position: "absolute", top: { xs: 30, lg: -20 } }}
      >
        <Grid item>
          <TopBar />
        </Grid>
        <Grid
          container
          item
          flexDirection="column"
          alignItems="center"
          rowGap={{ xs: "1rem", lg: "2rem" }}
        >
          <Grid item mb={{ xs: 0, lg: "-0.5rem" }}>
            <GreenTag
              text="For Protocols"
              fontSize={{ xs: "0.875rem", xl: "0.95vw" }}
            />
          </Grid>
          <Grid item xs={10} lg={8}>
            <Typography variant="h1" textAlign="center">
              Get more liquidity with more impact. Start now.
            </Typography>
          </Grid>
          <Grid item xs={10} lg={8}>
            <Typography
              variant="h6"
              color="secondary.main"
              fontWeight="300"
              textAlign="center"
            >
              Kaskade has pioneered the use of volume bootstrapping to control
              and direct liquidity incentives across decentralized exchanges and
              dApps
            </Typography>
          </Grid>
          <Grid item xs={10} lg={8} mt="1rem">
            <Typography variant="h4" textAlign="center">
              Contact form
            </Typography>
          </Grid>
          <Grid
            container
            item
            flexDirection="column"
            alignItems="center"
            width={{ xs: "100%", lg: "45vw" }}
            rowGap="0.75rem"
          >
            <Grid item xs={10} lg={12} width="100%">
              <OutlinedInput
                name="project_name"
                type="text"
                placeholder="Project Name"
                sx={{
                  width: "100%",
                  height: {xs: "3.5rem", xl: "4rem"},
                  padding: { xs: "1.5% 4%", sm: "2% 2%" },
                }}
              />
            </Grid>
            <Grid item xs={10} lg={12} width="100%">
              <OutlinedInput
                name="contact_name"
                type="text"
                placeholder="Contact Name"
                sx={{
                  width: "100%",
                  height: {xs: "3.5rem", xl: "4rem"},
                  padding: { xs: "1.5% 4%", sm: "2% 2%" },
                }}
                required
              />
            </Grid>
            <Grid item xs={10} lg={12} width="100%">
              <OutlinedInput
                type="email"
                name="email"
                placeholder="Contact Email"
                sx={{
                  width: "100%",
                  height: {xs: "3.5rem", xl: "4rem"},
                  padding: { xs: "1.5% 4%", sm: "2% 2%" },
                }}
                required
              />
            </Grid>
            <Grid item xs={10} lg={12} width="100%">
              <OutlinedInput
                type="text"
                name="message"
                placeholder="Short Description"
                multiline
                minRows={5}
                maxRows={8}
                required
                sx={{
                  width: "100%",
                  padding: {
                    xs: "5.5% 7%",
                    sm: "3.5% 5%",
                    lg: "2.75% 4%",
                    xl: "2.5% 3.35%",
                  },
                }}
              />
            </Grid>
            <Grid item xs={10} lg={12} width="100%" mt="0.5rem">
              <Button
                variant="contained"
                type="submit"
                value="Send Form"
                disabled={success === "sending" || !isChecked}
                fullWidth
              >
                {success === "sending" ? "Sending..." : "Send"}
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            item
            flexWrap="nowrap"
            xs={10}
            width={{ xs: "100%", lg: "45vw" }}
            justifyContent="flex-start"
            alignItems="center"
            mt={{ xs: 0, lg: "-0.75rem" }}
            columnGap="2%"
          >
            <Grid item>
              <Checkbox
                defaultChecked
                disabled={success === "sending"}
                onChange={() => setIsChecked(!isChecked)}
              />
            </Grid>
            <Grid item>
              <Typography
                variant="subtitle2"
                fontFamily="Outfit"
                color="secondary.main"
                fontWeight="300"
              >
                Sending this form you accept the Terms of Service and Privacy
                Policy rules.
              </Typography>
            </Grid>
          </Grid>
          <Grid item mt="-1%" xs={10} width={{ xs: "100%", lg: "45vw" }}>
            <Typography
              variant="subtitle2"
              fontFamily="Outfit"
              color={successColor}
              fontWeight="300"
            >
              {getMessage()}
            </Typography>
          </Grid>
        </Grid>
        <Footer />
      </Grid>
    </form>
  );
};

export default Contact;
