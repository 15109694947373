import {
  Drawer,
  IconButton,
  Stack,
  Grid,
  Box,
  Button,
  Link,
  Typography,
} from "@mui/material";
import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import KaskadeWhiteLogo from "../../assets/kaskadeWhiteLogo.svg";
import { views } from "components/common/constants/constants";

const MobileMenu = () => {
  const [open, setOpen] = useState<boolean>(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        px="5%"
      >
        <img
          alt="Kaskade White Logo"
          src={KaskadeWhiteLogo}
          width="100%"
          style={{ maxWidth: "8.9375rem", minWidth: "8.9375rem" }}
        />
        <IconButton
          aria-label="open drawer"
          edge="end"
          onClick={handleDrawerOpen}
        >
          <MenuIcon sx={{ color: "common.white" }} />
        </IconButton>
      </Stack>
      <Drawer variant="persistent" anchor="right" open={open}>
        <Grid
          container
          flexDirection="column"
          justifyContent="space-between"
          p="7% 5% 3% 5%"
          width="100vw"
          height="100vh"
        >
          <Grid
            container
            item
            justifyContent="center"
            alignItems="center"
            height="fit-content"
          >
            <Grid item xs={6}>
              <img
                alt="Kaskade White Logo"
                src={KaskadeWhiteLogo}
                width="100%"
                style={{ maxWidth: "8.9375rem", minWidth: "8.9375rem" }}
              />
            </Grid>
            <Grid item xs={6} textAlign="right">
              <IconButton onClick={handleDrawerClose} sx={{ padding: 0 }}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container item height="fit-content" rowGap="1.5rem">
            {views.map((item) => (
              <Grid item xs={12} key={item.name}>
                <Link fontSize="1.25rem" href={item.ref} target={item?.target}>
                  {item.name}
                </Link>
              </Grid>
            ))}
          </Grid>
          <Grid container item height="fit-content" rowGap="1.5rem">
            <Grid item xs={12}>
              <Button variant="contained" fullWidth>
                Launch App
              </Button>
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Typography
                variant="subtitle2"
                sx={{
                  opacity: 0.5,
                  fontFamily: "Outfit",
                }}
              >
                ©Kaskade {new Date().getFullYear()} Copyright
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Drawer>
    </Box>
  );
};

export default MobileMenu;
