import { useState } from "react";
import { Grid, IconButton, Link, Typography } from "@mui/material";
import KaskadeWhiteLogo from "../../assets/kaskadeWhiteLogo.svg";
import {
  footerSocial,
  media,
  resources,
} from "components/common/constants/constants";

const Footer = () => {
  const [hoverOver, setHoverOver] = useState<string>("");

  return (
    <Grid
      container
      item
      py="5%"
      px={{ xs: "5%", lg: 0 }}
      mt={{ xs: "20%", md: "10%" }}
      borderTop={{ xs: "none", md: "1px solid rgba(59, 57, 63, 1)" }}
    >
      <Grid
        container
        item
        xs={12}
        justifyContent="space-between"
        spacing="2rem"
      >
        <Grid item order={1} md={4} lg={5}>
          <img
            alt="Kaskade White Logo"
            src={KaskadeWhiteLogo}
            width="50%"
            height="auto"
            style={{
              maxWidth: "18rem",
            }}
          />
        </Grid>
        <Grid
          container
          item
          order={{ xs: 3, md: 2 }}
          xs={12}
          sm={8}
          md={6}
          lg={4}
          spacing="3rem"
          justifyContent="space-between"
        >
          <Grid
            container
            item
            xs={6}
            md={6}
            flexDirection="column"
            rowGap="0.75rem"
          >
            <Grid item mb="0.75rem">
              <Typography variant="h4">Resources</Typography>
            </Grid>
            {resources.map((resource) => (
              <Grid item key={resource.name}>
                <Link
                  sx={{ fontFamily: "Outfit" }}
                  target="_blank"
                  rel="nofollow noindex"
                  href={resource.href}
                >
                  {resource.name}
                </Link>
              </Grid>
            ))}
          </Grid>
          <Grid
            container
            item
            xs={6}
            md={6}
            flexDirection="column"
            rowGap="0.75rem"
          >
            <Grid item mb="0.75rem">
              <Typography variant="h4">Media</Typography>
            </Grid>
            {media.map((item) => (
              <Grid item key={item.name}>
                <Link
                  sx={{ fontFamily: "Outfit" }}
                  target="_blank"
                  rel="nofollow noindex"
                  href={item.href}
                >
                  {item.name}
                </Link>
              </Grid>
            ))}
          </Grid>
          {/*  <Grid
            container
            item
            xs={6}
            md={4}
            flexDirection="column"
            rowGap="0.75rem"
          >
        <Grid item mb="0.75rem">
              <Typography variant="h5">Company</Typography>
            </Grid>
            {company.map((item) => (
              <Grid item key={item.name}>
                <Link
                  sx={{ fontFamily: "Outfit" }}
                  target="_blank"
                  rel="nofollow noindex"
                  href={item.href}
                >
                  {item.name}
                </Link>
              </Grid>
            ))}
          </Grid>*/}
        </Grid>
        <Grid container item order={{ xs: 2, md: 3 }} alignItems="center">
          {footerSocial.map((item) => (
            <Grid item key={item.name}>
              <IconButton
                onMouseOver={() => setHoverOver(item.name)}
                onMouseOut={() => setHoverOver("")}
                href={item.href}
                target="_blank"
                rel="nofollow noindex"
              >
                {hoverOver === item.name ? item.gradientIcon : item.whiteIcon}
              </IconButton>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12} mt={{ xs: "2rem", md: "2%" }}>
        <Typography
          variant="subtitle2"
          sx={{
            opacity: 0.5,
            fontFamily: "Outfit",
          }}
        >
          ©Kaskade {new Date().getFullYear()} Copyright
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
