import { Box, keyframes } from "@mui/material";
import LoadingIcon from "components/icons/LoadingIcon";

const spin = keyframes`  
from {transform: rotate(0deg);}   
to {transform: rotate(359deg)} 
`;

const LoadingSpinner = () => {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        animation: `${spin} infinite 2s linear`,
      }}
    >
      <LoadingIcon sx={{fontSize: "4rem"}}/>
    </Box>
  );
};

export default LoadingSpinner;
