import { SvgIcon, SvgIconProps } from "@mui/material";

export default function MediumGradientIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 40 40">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.6305 7.44584C36.6305 5.19612 34.804 3.36963 32.5543 3.36963H7.44584C5.19612 3.36963 3.36963 5.19612 3.36963 7.44584V32.5543C3.36963 34.804 5.19612 36.6305 7.44584 36.6305H32.5543C34.804 36.6305 36.6305 34.804 36.6305 32.5543V7.44584ZM21.7895 20.0002C21.7895 24.381 18.2622 27.9323 13.9114 27.9323C9.56063 27.9323 6.0331 24.381 6.0331 20.0002C6.0331 15.6194 9.56031 12.0678 13.9114 12.0678C18.2625 12.0678 21.7895 15.6194 21.7895 20.0002ZM30.432 20.0002C30.432 24.1238 28.6684 27.468 26.4929 27.468C24.3173 27.468 22.5537 24.1238 22.5537 20.0002C22.5537 15.8766 24.317 12.5325 26.4926 12.5325C28.6682 12.5325 30.4318 15.8755 30.4318 20.0002H30.432ZM33.967 20.0002C33.967 23.6939 33.3468 26.6901 32.5816 26.6901C31.8164 26.6901 31.1964 23.6948 31.1964 20.0002C31.1964 16.3056 31.8166 13.3103 32.5816 13.3103C33.3466 13.3103 33.967 16.3054 33.967 20.0002Z"
        fill="url(#paint0_linear_6780_762)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6780_762"
          x1="27.326"
          y1="5.88055"
          x2="16.3469"
          y2="35.713"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8BFF" />
          <stop offset="1" stopColor="#1E9DFF" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}
