import Redacted from "../../../assets/redacted.png";
import NxGen from "../../../assets/nxgen.png";
import VirtualBacon from "../../../assets/virtualBacon.png";
import PixelPartner from "../../../assets/pixelPartner.png";
import Marshland from "../../../assets/marshland.png";
import Capital369 from "../../../assets/369capital.png";
import Liquity from "../../../assets/liquity.png";
import CryptoOasis from "../../../assets/cryptoOasis.png";
import HerculesVentures from "../../../assets/herculesVentures.png";
import AndromedaCapital from "../../../assets/andromedaCapital.png";
import Momentum from "../../../assets/momentum.png";
import StarkWare from "../../../assets/starkware.png";
import MediumIcon from "../../icons/MediumIcon";
import XIcon from "../../icons/XIcon";
import DiscordIcon from "../../icons/DiscordIcon";
import LinkedinIcon from "../../icons/LinkedinIcon";
import TelegramIcon from "../../icons/TelegramIcon";
import XGradientIcon from "../../icons/XGradientIcon";
import DiscordGradientIcon from "../../icons/DiscordGradientIcon";
import TelegramGradientIcon from "../../icons/TelegramGradientIcon";
import MediumGradientIcon from "../../icons/MediumGradientIcon";
import LinkedinGradientIcon from "../../icons/LinkedinGradientIcon";

export const views = [
  {
    name: "Home",
    ref: "/",
  },
  /*{
    name: "Litepaper",
  },*/
  {
    name: "Contact",
    ref: "/contact",
  },
  {
    name: "Docs",
    ref: "https://kaskade.gitbook.io/kaskade",
    target: "_blank"
  },
  /*{
    name: "FAQ",
  },*/
];

export const topBarViews = [
  {
    name: "Home",
    ref: "/",
  },
 /* {
    name: "Litepaper",
  },*/
  {
    name: "Contact",
    ref: "/contact",
  },
  {
    name: "Docs",
    ref: "https://kaskade.gitbook.io/kaskade",
    target: "_blank"
  },
 /* {
    name: "FAQ",
  },*/
  /*{
    name: "About",
    tabs: [
      {
        name: "Contact",
        ref: "/contact",
      },
      {
        name: "For Partners",
      },
      {
        name: "FAQ",
      },
    ],
  },*/
];

export const partners1 = [
  {
    name: "Redacted",
    image: Redacted,
  },
  {
    name: "NxGen",
    image: NxGen,
  },
  {
    name: "VirtualBacon",
    image: VirtualBacon,
  },
  {
    name: "PixelPartner",
    image: PixelPartner,
  },
  {
    name: "Marshland",
    image: Marshland,
  },
  {
    name: "Capital369",
    image: Capital369,
  },
];

export const partners2 = [
  {
    name: "Liquity",
    image: Liquity,
  },
  {
    name: "CryptoOasis",
    image: CryptoOasis,
  },
  {
    name: "HerculesVentures",
    image: HerculesVentures,
  },
  {
    name: "AndromedaCapital",
    image: AndromedaCapital,
  },
  {
    name: "Momentum",
    image: Momentum,
  },
  {
    name: "StarkWare",
    image: StarkWare,
  },
];

export const resources = [
 /* { name: "For Traders", href: "" },
  { name: "Protocols", href: "" },
  { name: "KKD Tokenomics", href: "" },
  { name: "Our Supporters", href: "" },*/
  { name: "Connect with us", href: "https://x.com/Kaskade_Finance" },
];

export const media = [
  { name: "Blog", href: "https://medium.com/@KaskadeFinance" },
  /*{ name: "Press", href: "" },*/
  { name: "Work with us", href: "https://www.linkedin.com/in/rowlstone/" },
  /*{ name: "Brand Guide", href: "" },*/
];

export const company = [
  /*{ name: "About Us", href: "" },
  { name: "Contacts", href: "" },
  { name: "Address", href: "" },*/
];

export const footerSocial = [
  {
    name: "Medium",
    href: "https://medium.com/@KaskadeFinance",
    whiteIcon: <MediumIcon sx={{ fontSize: "2rem" }} />,
    gradientIcon: <MediumGradientIcon sx={{ fontSize: "2rem" }} />,
  },
  {
    name: "X",
    href: "https://twitter.com/Kaskade_Finance",
    whiteIcon: <XIcon sx={{ fontSize: "2rem" }} />,
    gradientIcon: <XGradientIcon sx={{ fontSize: "2rem" }} />,
  },
  {
    name: "Discord",
    href: "https://discord.com/invite/am4RGQndS4",
    whiteIcon: <DiscordIcon sx={{ fontSize: "2rem" }} />,
    gradientIcon: <DiscordGradientIcon sx={{ fontSize: "2rem" }} />,
  },
  {
    name: "Linkedin",
    href: "https://www.linkedin.com/company/kaskade-finance/",
    whiteIcon: <LinkedinIcon sx={{ fontSize: "2rem" }} />,
    gradientIcon: <LinkedinGradientIcon sx={{ fontSize: "2rem" }} />,
  },
  {
    name: "Telegram",
    href: "https://t.me/kaskade_finance",
    whiteIcon: <TelegramIcon sx={{ fontSize: "2rem" }} />,
    gradientIcon: <TelegramGradientIcon sx={{ fontSize: "2rem" }} />,
  },
];

export const heroSocial = [
  {
    name: "X",
    href: "https://twitter.com/Kaskade_Finance",
    whiteIcon: <XIcon sx={{ fontSize: {xs: "1.75rem", xl: "1.7vw"} }} />,
    gradientIcon: <XGradientIcon sx={{ fontSize: {xs: "1.75rem", xl: "1.7vw"} }} />,
  },
  {
    name: "Discord",
    href: "https://discord.com/invite/am4RGQndS4",
    whiteIcon: <DiscordIcon sx={{ fontSize: {xs: "1.75rem", xl: "1.7vw"} }} />,
    gradientIcon: <DiscordGradientIcon sx={{ fontSize: {xs: "1.75rem", xl: "1.7vw"} }} />,
  },
  {
    name: "Telegram",
    href: "https://t.me/kaskade_finance",
    whiteIcon: <TelegramIcon sx={{ fontSize: {xs: "1.75rem", xl: "1.7vw"} }} />,
    gradientIcon: <TelegramGradientIcon sx={{ fontSize: {xs: "1.75rem", xl: "1.7vw"} }} />,
  },
];

