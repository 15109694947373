import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Box,
  Button,
  Grid,
  Slide,
  Toolbar,
  Typography,
  useMediaQuery,
  useScrollTrigger,
  Link,
} from "@mui/material";
import KaskadeHorizontalLogoWhite from "../icons/KaskadeHorizontalLogoWhite";
import MobileMenu from "./MobileMenu";
import { theme } from "../../utils/theme";
import styled from "styled-components";
import { topBarViews } from "components/common/constants/constants";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const NavbarDropdownContent = styled.div`
display: none;
position: absolute;
left: -1.5rem;
background-color: transparent;
min-width: 7.5rem;
padding: 0.1rem;
z-index: 99;
border-radius: 0.75rem;
background:
  linear-gradient(to left, #FF8BFF, #1E9DFF)
}}
`;

const NavbarDropdown = styled.div`
  position: relative;
  display: inline-block;
  overflow: visible;
  color: ${theme.palette.secondary.main};
  &:hover ${NavbarDropdownContent} {
    display: block;
  }
`;

const TopBar = () => {
  const navigate = useNavigate();
  const [transition, setTransition] = useState<boolean>(false);

  function HideOnScroll({ children }) {
    const trigger = useScrollTrigger({
      target: window ? window : undefined,
    });
    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {children}
      </Slide>
    );
  }

  const mobileSize = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    setTransition(true);
  }, []);

  return (
    <Box
      sx={{
        marginTop: "8vw",
      }}
    >
      <HideOnScroll>
        <AppBar
          sx={{
            boxShadow: "none",
            backgroundColor: "primary.main",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Slide
            direction="down"
            in={transition}
            mountOnEnter
            unmountOnExit
            timeout={1300}
          >
            <Toolbar
              disableGutters
              sx={{
                height: { xs: "5rem", xl: "5vw" },
                width: {xs: "100%", md:  "80%", xl: "70%"},
              }}
            >
              {mobileSize ? (
                <Grid item xs={12}>
                  <MobileMenu />
                </Grid>
              ) : (
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item xs={2}>
                    <KaskadeHorizontalLogoWhite
                      onClick={() => navigate("/")}
                      sx={{
                        fontSize: "16vw",
                        maxWidth: { xs: "10rem", xl: "11.5vw" },
                        height: "fit-content",
                        marginTop: "0.25rem",
                        cursor: "pointer",
                      }}
                    />
                  </Grid>
                  <Grid
                    container
                    item
                    justifyContent="flex-end"
                    xs={12}
                    md={6}
                    lg={7}
                    xl={7.5}
                    columnGap={{  md: "2.5rem", lg: "3.5rem", xl: "2vw" }}
                  >
                    {topBarViews.map((view) => (
                      <Grid width="fit-content" item key={view.name}>
                        {view.name === "About" ? (
                          <NavbarDropdown>
                            <Button
                              sx={{
                                padding: 0,
                                color: "secondary.main",
                                paddingBottom: "0.75rem",
                                display: "flex",
                                columnGap: "0.5rem",
                              }}
                            >
                              {view.name}
                              <KeyboardArrowDownIcon />
                            </Button>
                            <NavbarDropdownContent>
                              <div
                                style={{
                                  background: theme.palette.primary.main,
                                  padding: "0.5rem 1.5rem 0.75rem 1.5rem",
                                  borderRadius: "0.75rem",
                                  width: "10rem",
                                }}
                              ></div>
                            </NavbarDropdownContent>
                          </NavbarDropdown>
                        ) : (
                          <Link href={view?.ref} target={view?.target}>
                            <Typography
                              variant="subtitle1"
                              color="secondary.main"
                              fontSize={{ xl: "0.925vw" }}
                            >
                              {view.name}
                            </Typography>
                          </Link>
                        )}
                      </Grid>
                    ))}
                  </Grid>
                  <Grid container item alignItems="center" width="fit-content">
                    <Grid item width="fit-content">
                      <Button
                        variant="contained"
                        href="https://app.kaskade.finance"
                        target="_blank"
                        rel="nofollow noindex"
                        sx={{
                          fontSize: { xs: "0.75rem", xl: "0.75vw" },
                          padding: "0.75vw 2.5vw !important",
                        }}
                      >
                        Launch App
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Toolbar>
          </Slide>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
    </Box>
  );
};
export default TopBar;
